import { kebabCase } from '@/helpers'
export const state = () => ({
  loading: false,
  carePlanLoaded: false,
  carePlan: {},
  carePlanTypes: [],
  sidebarCarePlanItems: [],
  activeCarePlanSection: null,
  carePlanSectionNotesHistory: [],
  currentNote: null,
  carePlanNotes: [],
  carePlanPDF: null,
  carePlanPDFURL: null,
  pdfUploading: false
})
export const getters = {
  fetchActiveCarePlanSection: (state) => {
    return state.carePlan.sections?.find(section => section.id === state.activeCarePlanSection);
  },
  fetchCarePlanSectionFromId: (state) => (sectionId) => {
    return state.carePlan.sections?.find(section => section.id === sectionId);
  },
  fetchHistoryForCurrentSection: (state) => {
    return state.carePlanSectionNotesHistory.filter(note => note.section.id === state.activeCarePlanSection);
  },
  fetchEligibleStatusesForSection: (state) => {
    let section = state.carePlan.sections?.find(section => section.id === state.activeCarePlanSection);
    console.log('fetching active care plan allowed statuses', section)
    let eligibleStatuses = section.eligibleStatuses;
    console.log('eligible statuses array', eligibleStatuses);
    eligibleStatuses = eligibleStatuses.map((state, index) => ({
      label: state.replace(/_/g, ' ').toLowerCase().replace(/^\w/, c => c.toUpperCase()),
      value: state
    }));
    return eligibleStatuses
  }
}
export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCarePlanLoaded(state, loaded) {
    state.carePlanLoaded = loaded;
  },
  setCarePlan(state, carePlan) {
    state.carePlan = carePlan;
  },
  setCarePlanTypes(state, carePlanTypes) {
    state.carePlanTypes = carePlanTypes;
  },
  setSidebarCarePlanItems(state, items) {
    state.sidebarCarePlanItems = items;
  },
  setActiveCarePlanSection(state, sectionId) {
    state.activeCarePlanSection = sectionId;
  },
  setCarePlanSectionNotesHistory(state, history) {
    state.carePlanSectionNotesHistory = history;
  },
  setCurrentNote(state, note) {
    state.currentNote = note;
  },
  setCarePlanNotes (state, notes) {
    state.carePlanNotes = [...notes];
  },
  setCarePlanPDF(state, pdf) {
    state.carePlanPDF = pdf;
  },
  setCarePlanPDFURL(state, pdf) {
    state.carePlanPDFURL = pdf;
  },
  setPdfUploading(state, uploading) {
    state.pdfUploading = uploading;
  }
}
export const actions = {
  async initProfileCarePlan ({ dispatch, commit }, { profileId }) {
    commit('setLoading', true);
    await Promise.all([dispatch('fetchProfileCarePlan', profileId), dispatch('fetchCarePlanTypes')]);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
    commit('setLoading', false);
    commit('setCarePlanLoaded', true);
  },

  async fetchProfileCarePlan({ commit, dispatch }, profileId) {
    try {
      const response = await this.$apiv2.getPatientCarePlan(profileId);
      commit('setCarePlan', response);
    }
    catch (error) {
      commit('setLoading', false);
      commit('setCarePlanLoaded', true);
    }
    
  },

  async fetchCarePlanTypes({ commit }) {
    const response = await this.$apiv2.getCarePlanTypes();
    commit('setCarePlanTypes', response);
  },
  // This function is not used in the codebase
  applyActiveCarePlanSection({ state, commit }, { sectionType }) {
    let activeSectionId = state.carePlan.sections?.find(section => section.type === sectionType)?.id;

    commit('setActiveCarePlanSection', activeSectionId);
  },
  applyActiveCarePlanSectionById({ commit }, { sectionId }) {
    commit('setActiveCarePlanSection', sectionId);
  },
  async createCarePlanSectionNote ({ dispatch }, { profileId, sectionId, text }) {
    console.log('createCarePlanSectionNote', profileId, sectionId, text);
    let payload = {
      text: text
    };
    await this.$apiv2.createPatientCarePlanSectionNote(profileId, sectionId, payload);
    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },
  async updateCarePlanSectionNote({ dispatch }, { profileId, updatedSection }) {
    await this.$apiv2.updatePatientCarePlanSection(profileId, updatedSection);

    await dispatch('fetchProfileCarePlan', profileId);
  },
  async updateCarePlanSectionHistoryNote ({ dispatch, commit }, { profileId, sectionId, noteId, text }) {
    let payload = {
      id: noteId,
      text: text
    };
    await this.$apiv2.updatePatientCarePlanHistoryNote(profileId, sectionId, payload);

    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },

  async deleteCarePlanSectionHistoryNote ({ dispatch }, { profileId, sectionId, noteId }) {
    await this.$apiv2.deletePatientCarePlanHistoryNote(profileId, sectionId, noteId);

    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },

  async sortCarePlanSectionHistoryByCreatedDate({ commit }, sectionHistory ) {
    let sortedSectionHistory = sectionHistory.sort((a, b) => {
      return new Date(b.createdDate) - new Date(a.createdDate);
    });
    commit('setCarePlanSectionNotesHistory', sortedSectionHistory);
  },

  async fetchPatientCarePlanSectionNotesHistory({ dispatch }, { profileId, sectionId }) {
    const response = await this.$apiv2.getPatientCarePlanSectionNotes(profileId, sectionId);
    await dispatch('sortCarePlanSectionHistoryByCreatedDate', response);
  },

  async fetchPatientCarePlanNotes({ commit, state }, { profileId }) {
    console.log('fetching care plan notes', profileId)
    if (Object.keys(state.carePlan).length > 0) {
      try {
        const response = await this.$apiv2.getPatientCarePlanNotes(profileId);
        commit('setCarePlanNotes', response);
      }
      catch (error) {
        commit('setLoading', false);
        commit('setCarePlanLoaded', true);
      }
    }
  },

  async createCarePlanNote ({ dispatch }, { profileId, text }) {
    let payload = {
      text: text
    };
    await this.$apiv2.createPatientCarePlanNote(profileId, payload);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },

  async updateCarePlanNote ({ dispatch }, { profileId, noteId, text }) {
    let payload = {
      id: noteId,
      text: text
    };
    await this.$apiv2.updatePatientCarePlanNote(profileId, payload);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },

  async deleteCarePlanNote ({ dispatch }, { profileId, noteId }) {
    await this.$apiv2.deletePatientCarePlanNote(profileId, noteId);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },
  async fetchPatientCarePlanPDF({ commit }, { profileId }) {
    const response = await this.$apiv2.getPatientCarePlanPDF(profileId);
    commit('setCarePlanPDF', response)
    const carePlanPDFURL = URL.createObjectURL(response);
    console.log('carePlanPDFURL', carePlanPDFURL);
    commit('setCarePlanPDFURL', carePlanPDFURL);
  },

  async uploadCarePlanPDF({ dispatch, state, commit }, { profileId }) {
    commit('setPdfUploading', true);
    let document = state.carePlanPDF;
    let providerId = undefined;
    await this.$apiv2.uploadCarePlanPDF(profileId, providerId, document,);
    await dispatch('fetchPatientCarePlanPDF', { profileId });
    commit('setPdfUploading', false);
  },

  async fetchAndUploadCarePlanPDF({ dispatch }, { profileId }) {
    await dispatch('fetchPatientCarePlanPDF', { profileId });
    await dispatch('uploadCarePlanPDF', { profileId });
  },

  async clearCarePlan({ commit }) {
    commit('setCarePlan', {});
  },

  async clearState({ commit }) {
    commit('setLoading', false);
    commit('setCarePlanLoaded', false);
    commit('setCarePlan', {});
    commit('setCarePlanTypes', []);
    commit('setSidebarCarePlanItems', []);
    commit('setActiveCarePlanSection', null);
    commit('setCarePlanSectionNotesHistory', []);
    commit('setCurrentNote', null);
    commit('setCarePlanNotes', []);
    commit('setCarePlanPDF', null);
    commit('setCarePlanPDFURL', null);
    commit('setPdfUploading', false);
  }
}